<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" width="90%" custom-class="top5" top="5vh">
    <el-row>
      <el-col :span="8">
        <el-card class="margin-xs">
          <l-description :option="descOption" :data="descData">
            <template slot="projectType">
              <p class="text-blue" v-if="dataInfo.projectType == 'duty'">管理类</p>
              <p class="text-purple" v-if="dataInfo.projectType == 'asset'">资产类</p>
              <p class="text-purple" v-if="dataInfo.projectType == 'item'">临时类</p>
            </template>
          </l-description>
        </el-card></el-col
      >
      <el-col :span="16">
        <el-card class="margin-xs"> <regular-trigger :data="triggerInfo" @trigger="triggerChange"></regular-trigger> </el-card
      ></el-col>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import regularTrigger from '@/views/common/_regular_trigger/trigger'
export default {
  components: {
    regularTrigger
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      triggerInfo: null,
      descOption: {
        column: 1,
        labelStyle: {
          width: '100px'
        }
      },
      descData: null
    }
  },
  mounted() {
    this.descData = [
      { label: '项目名称', text: this.dataInfo.projectName, span: 2 },
      { label: '项目类型', slot: 'projectType' },
      { label: '责任部门', text: this.dataInfo.departName },
      { label: '实施部门', text: this.dataInfo.effectDepartName },
      { label: '委托部门', text: this.dataInfo.trustDepartName },
      { label: '责任人', text: this.dataInfo.userName },
      { label: '实施人', text: this.dataInfo.effectUserName },
      { label: '委托人', text: this.dataInfo.trustUserName },
      { label: '项目要求', text: this.dataInfo.requirement, span: 2 }
    ]
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    save() {
      this.triggerInfo.enterpriseCode = this.dataInfo.enterpriseCode
      this.triggerInfo.projectId = this.dataInfo.projectId
      this.$lgh.post('api/enterprise/safely/regular/project/trigger/addoredit', this.triggerInfo).then(res => {
        this.visible = false
        this.onsuccess && this.onsuccess(this.dataInfo)
      })
    },
    triggerChange(data) {
      this.triggerInfo = data
    }
  }
}
</script>

<style></style>
